export function common() {
	!function(){	// limit scope
		$(function(){
			const breakPoint = 768; //ブレイクポイントの設定
			let wid = window.innerWidth,
					resizeTimer = false;

	/*
	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

	//   function - parts

	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	*/

	/* change Img
	*********************************************/
	const changeImg = document.getElementsByClassName('change-img');
	function changeImgSp(){
		for (let i = 0; i < changeImg.length; i++) {
		  changeImg[i].setAttribute("src", changeImg[i].getAttribute("src").replace(/_pc\./, '_sp.'));
		}
	}
	function changeImgPc(){
		for (let i = 0; i < changeImg.length; i++) {
		  changeImg[i].setAttribute("src", changeImg[i].getAttribute("src").replace(/_sp\./, '_pc.'));
		}
	}

	/*
	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

	//   Common - PC / SP

	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	*/
	const elem = document.getElementsByClassName('sticky');
	Stickyfill.add(elem);

	if(document.getElementsByClassName('box-1').length){
		$(window).on('load',function(){
			$(function () {
		    const $sticky = $('.box-1__side'); // 追従する要素
		    let startSticky = $sticky.offset().top, // 追従する要素の初期位置
						endSticky = $('.contact').offset().top;
		    // 要素が追従する処理
		    function fixFunction() {
		      let scrolltop = $(window).scrollTop();
		      // スクロールが初期位置を通過しているとき
		      if (scrolltop >= startSticky && scrolltop < endSticky) {
		        $sticky.addClass('is-fixed');
		      } else {
		        $sticky.removeClass('is-fixed');
		      }
		    }

				if (matchMedia('(max-width: 798px)').matches) {
					$(window).on('load scroll', function () {
			      fixFunction();
			    });
				}
			});
		});
	}




	/*
	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

	//   Only Pc Size Processing

	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	*/
	function pcSizeOnly(){
	changeImgPc();
	}

	/*
	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

	//   Only Sp Size Processing

	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	*/
	function spSizeOnly(){
	changeImgSp();
	}

	/*
	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■

	//   Break Point & Window Resize

	■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
	*/
			function descriminateBp(){
				wid = window.innerWidth;
				if(wid <= breakPoint){
					spSizeOnly();
				}else if(wid > breakPoint){
					pcSizeOnly();
				}
			}
			descriminateBp();

			window.addEventListener('resize', () => {
				if(wid > window.innerWidth || wid < window.innerWidth){
					if (resizeTimer !== false) {
						clearTimeout(resizeTimer);
					}
					resizeTimer = setTimeout(descriminateBp, 100);
				}
			});
		});
	}();
}
