//import '@babel/polyfill';
//import swiperBase from "../libraly/swiper.min";
//import sticky from "../libraly/stickyfill.min";

import {common} from "../modules/common";
import {nav} from "../modules/nav";

import {smoothScroll} from "../modules/smooth-scroll";
import {scrollAnimation} from "../modules/scroll-animation";
import {swiper} from "../modules/swiper";
import {scrollify} from "../modules/scrollify";
import {sideNav} from "../modules/side-nav";
import {accordion} from "../modules/accordion";
import {fbWidget} from "../modules/fbWidget";

common();
nav();
smoothScroll();
scrollAnimation();
swiper();
scrollify();
sideNav();
accordion ();
fbWidget();
