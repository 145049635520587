export function fbWidget() {
	if($('#fb').length){
		// ページプラグインの埋め込みコードを返す。
		function pagePluginCode(w) {
			let h = 500;

			return '<div class="fb-page" data-href="https://www.facebook.com/%E7%AD%91%E5%BE%8C%E5%B7%9D%E6%B8%A9%E6%B3%89%E6%97%85%E9%A4%A8%E7%B5%84%E5%90%88-2127963154161784/" data-tabs="timeline" data-width="' + w + '" data-height="' + h + '" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/%E7%AD%91%E5%BE%8C%E5%B7%9D%E6%B8%A9%E6%B3%89%E6%97%85%E9%A4%A8%E7%B5%84%E5%90%88-2127963154161784/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/%E7%AD%91%E5%BE%8C%E5%B7%9D%E6%B8%A9%E6%B3%89%E6%97%85%E9%A4%A8%E7%B5%84%E5%90%88-2127963154161784/">筑後川温泉旅館組合</a></blockquote></div>';
		}

		// ページプラグインを追加する要素
		const facebookWrap = $('#fb');
		let fbBeforeWidth = '', // 前回変更したときの幅
		    fbWidth = facebookWrap.width(), // 今回変更する幅
		    fbTimer = false;

		$(window).on('load resize', function() {
			if (fbTimer !== false) {
				clearTimeout(fbTimer);
			}
			fbTimer = setTimeout(function() {
				fbWidth = facebookWrap.width(); // 変更後の幅を取得
				// 前回の幅から変更があった場合のみ処理
				// スマホだとスクロール時にリサイズが発生することがあるため
				if(fbWidth != fbBeforeWidth) {
					facebookWrap.html(pagePluginCode(fbWidth)); // ページプラグインのコード変更
					window.FB.XFBML.parse(); // ページプラグインの再読み込み
					fbBeforeWidth = fbWidth; // 今回変更分を保存しておく
				}
			}, 200);
		});
	}
}
