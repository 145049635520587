/* スクロールアニメーション
*********************************************/
export function scrollAnimation() {
	const scrollAnimationClass = 'sa',
				scrollAnimationShowClass = 'is-show',
				triggerMarginDefault = 100,
				scrollAnimationElm = document.getElementsByClassName(scrollAnimationClass);

	const scrollAnimationFunc = function() {
		const dataMargin = scrollAnimationClass + '_margin',
					dataTrigger = scrollAnimationClass + '_trigger',
					dataDelay = scrollAnimationClass + '_delay';
	  for(let i = 0; i < scrollAnimationElm.length; i++) {
			let triggerMargin = triggerMarginDefault,
					elm = scrollAnimationElm[i],
					showPos = 0;
	    if(elm.dataset[dataMargin] != null) {
	      triggerMargin = parseInt(elm.dataset[dataMargin]);
	    }
	    if(elm.dataset[dataTrigger]) {
	      showPos = document.querySelector(elm.dataset[dataTrigger]).getBoundingClientRect().top + triggerMargin;
	    } else {
	      showPos = elm.getBoundingClientRect().top + triggerMargin;
	    }
	    if (window.innerHeight > showPos) {
	      let delay = (elm.dataset[dataDelay])? elm.dataset[dataDelay] : 0;
	      setTimeout(function(index) {
	        scrollAnimationElm[index].classList.add('is-show');
	      }.bind(null, i), delay);
	    }
	  }
	}
	window.addEventListener('load', scrollAnimationFunc);
	window.addEventListener('scroll', scrollAnimationFunc);
}
