export function scrollify() {
  if($('.method').length){
    const pcOption = {
      section : '.method__item', // 対象を指定
      sectionName: 'data-section-name',
      easing: "swing", // イージングをしてい(jQueryのanimation)
      scrollSpeed: 1100, // スクロール時の速度
      scrollbars: true, // スクロールバーを表示するか
      setHeights: false,
      updateHash: false,
    };
    const spOption = {
      section : '.method__item', // 対象を指定
      sectionName: 'data-section-name',
      easing: "swing", // イージングをしてい(jQueryのanimation)
      scrollSpeed: 1100, // スクロール時の速度
      scrollbars: true, // スクロールバーを表示するか
      setHeights: false,
      updateHash: false,
      interstitialSection:".method__note,.contact,.footer"
    };

    $(window).on('load resize', function(){
      if (matchMedia('(max-width: 798px)').matches) {
        $.scrollify(spOption);
      } else {
        $.scrollify(pcOption);
      }
    });
  }

  //$.scrollify(option); // scrollifyの実行
/*
  if (matchMedia('(max-width: 798px)').matches) {
    $(window).on('load scroll', function () {
      let lastSection = 550 * 4,
          scrolltop = $(window).scrollTop();
      console.log(scrolltop);
      if(scrolltop >= lastSection){
        //$.scrollify.disable();
        $.scrollify.destroy();
      } else if(scrolltop < lastSection) {

        //$.scrollify.enable();
        //$.scrollify.update(option);
        //$.scrollify.isDisabled()
        //$.scrollify({touchScroll: true});
        //$.scrollify(option);
      }
    });
  }
*/
}
