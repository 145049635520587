/* swiper
*********************************************/
export function swiper() {
	let storySlide = new Swiper('.story-slide', {
		autoplay: {
			delay: 5500,
			disableOnInteraction: true
		},
	  loop: true,
		speed: 1000,
	  autoHeight: false,
		slidesPerView: 2.7,
	  spaceBetween: 10,
	  initialSlide: 1,
		centeredSlides: true,
		resizeReInit: true,
		breakpoints: {
	    768: {
	      slidesPerView: 1,
	      spaceBetween: 0
	    }
		}
	});

	const hotelImages = document.getElementsByClassName('hotel-slide__images'),
				hotelThumbs = document.getElementsByClassName('hotel-slide__thumbs');

	for (let i = 0; i < hotelImages.length; i++) {
		let hotelImage = hotelImages[i].getElementsByTagName('img');

		let hotelImagesIndex = new Swiper(hotelImages[i], {
			speed: 1000,
		  autoHeight: false,
			pagination: {
		    el: hotelThumbs[i],
		    clickable: true,
		    renderBullet: function (index, className) {
					return '<button class="hotel-slide__thumb ' + className + '">' + '<img src="thumb" alt="">' + '</button>';
		    },
		  },
		});
	};

	for (let i = 0; i < hotelThumbs.length; i++) {
		let hotelImage = hotelImages[i].getElementsByTagName('img'),
				hotelThumbImage = hotelThumbs[i].getElementsByTagName('img');

		for (let i = 0; i < hotelImage.length; i++) {
			hotelThumbImage[i].setAttribute("src", hotelThumbImage[i].getAttribute("src").replace(/thumb/, hotelImage[i].src));
		}
	};
}
