/* side-nav
*********************************************/
export function sideNav() {
  let navLink;

  if($('.activity-nav').length){
    navLink = $('.activity-nav__item a'); // ナビゲーションのリンクを指定
  } else if($('.hotel-nav').length) {
    navLink = $('.hotel-nav__item a'); // ナビゲーションのリンクを指定
  } else if($('.method-nav'.length)) {
    navLink = $('.method-nav__item a'); // ナビゲーションのリンクを指定
  }

  if($('.activity-nav').length || $('.hotel-nav').length || $('.method-nav'.length)){
    let contentsArr = new Array(); // 各コンテンツのページ上部からの開始位置と終了位置を配列に格納しておく

    for (let i = 0; i < navLink.length; i++) {
      let targetContents = navLink.eq(i).attr('href'); // コンテンツのIDを取得

      // ページ内リンクでないナビゲーションが含まれている場合は除外する
      if(targetContents.charAt(0) === '#') {
        let targetContentsTop = $(targetContents).offset().top,// ページ上部からコンテンツの開始位置までの距離を取得
    				targetContentsBottom = targetContentsTop + $(targetContents).outerHeight() - 1;// ページ上部からコンテンツの終了位置までの距離を取得
    		contentsArr[i] = [targetContentsTop, targetContentsBottom];
        //console.log(targetContentsTop);
      }
    };

    // 現在地をチェックする
    function currentCheck() {
      let windowScrolltop = $(window).scrollTop();
      //console.log(windowScrolltop);

      for (let i = 0; i < contentsArr.length; i++) {
    		// 現在のスクロール位置が、配列に格納した開始位置と終了位置の間にあるものを調べる
    		if(contentsArr[i][0] <= windowScrolltop && contentsArr[i][1] >= windowScrolltop) {
    			navLink.removeClass('is-current');
    			navLink.eq(i).addClass('is-current');

    			i == contentsArr.length;
    		}
      };
    }

    window.addEventListener('scroll', () => {
    	currentCheck();
    });
  }
}
