export function accordion() {
  if(document.getElementsByClassName('page-nav').length){
    const btn = document.querySelector(".page-nav__btn"),
          btnState = document.querySelector(".page-nav__state"),
          navItem = document.querySelectorAll(".page-nav__item > a");

    btn.addEventListener('click', function() {
      btn.classList.toggle('is-opened');
      btn.nextElementSibling.classList.toggle('is-opened')
      if(btn.classList.contains('is-opened')){
        btnState.textContent = '閉じる';
      } else {
        btnState.textContent = '見る';
      }
    }, false);


    for (let i = 0; i < navItem.length; i++) {
      navItem[i].addEventListener('click', function() {
        btn.classList.toggle('is-opened');
        btn.nextElementSibling.classList.toggle('is-opened');
        if(btn.classList.contains('is-opened')){
          btnState.textContent = '閉じる';
        } else {
          btnState.textContent = '見る';
        }
      }, false);
    }
  }

}
